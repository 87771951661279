<template>
  <div class="uk-width-1-1 uk-height-1-1 bg-white">
    <div
      class="uk-child-width-1-2@s uk-child-width-1-2@m uk-text-center uk-margin-remove"
      uk-grid
    >
      <div class="section-cover-login">
        <div class="bg-login">
          <img
            :src="`${images}/bg-login.png`"
            alt="bg-login"
          >
        </div>
        <div class="uk-margin-medium-top uk-text-left">
          <h1 class="headline-login">
            Manage and monitor the process of coffee
          </h1>
        </div>
      </div>

      <div>
        <div class="section-login uk-flex-column">
          <div class="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <img
              :src="`${images}/logo-varion.png`"
              alt="logo-varion"
              width="238"
              height="58"
            >
            <h4 class="logo-text uk-margin-large-bottom">
              Admin Dashboard
            </h4>
          </div>
          <form
            class="uk-form-auth"
            @submit.prevent="onSubmit"
          >
            <fieldset class="uk-fieldset uk-text-left">
              <h3 class="uk-legend">
                Forgot Password
              </h3>
              <h5>We will send you an email to reset your password</h5>
              <div class="uk-margin uk-text-left">
                <div class="uk-inline uk-width-1-1">
                  <label>Email</label>
                  <input
                    v-model="email"
                    v-validate="'required|email'"
                    name="email"
                    class="uk-input"
                    type="text"
                    autocomplete="on"
                    placeholder="Enter Email"
                    :class="{'uk-form-danger': errors.has('email')}"
                  >
                </div>
                <span
                  v-show="errors.has('email')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('email') }}</span>
              </div>
            
              <button
                v-if="!getToggleModalAddEditDiscard.loading"
                class="uk-button uk-button-primary uk-width-1-1"
                :class="isEmailValid == false ? 'uk-button-primary' : ''"
                type="submit"
                :disabled="!isEmailValid"
              >
                Send
              </button>
              <button
                v-else
                class="uk-button uk-button-primary uk-width-1-1"
                type="button"
                disabled
              >
                <div uk-spinner />
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <modal-auth-info
      :images="images"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import ModalAuthInfo from '@/components/globals/modals/ModalAuthInfo'

export default {
  components: {
    ModalAuthInfo
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isEmailValid: true,
      email: ''
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'auth/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapActions({
      actionForgotPassword: 'auth/forgotPassword'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'auth/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.forgotPassword()
          this.isEmailValid = false
        } else {
          this.isEmailValid = true
        }
      })
    },
    forgotPassword() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loading: true})
      this.actionForgotPassword({email: this.email}).then(result => {
        if(result?.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard, 
              targetModals: true,  
              title: 'Please check your email to reset password',
              type: 'reset-password',
              route: 'Login',
              customTitleBtnOk: 'Okay',
              loading: false
            })
          }, 500)
        } else {
          this.setToggleModalAddEditDiscard({
            ...this.getToggleModalAddEditDiscard, 
            targetModals: false,  
            title: '',
            description: '',
            type: '',
            route: '',
            customTitleBtnOk: '',
            loading: false
          })
        }
      })
    }
  }
}
</script>


<style scoped>
 .uk-margin.uk-text-left {
    margin-top: 24px !important;
  }
  h3 {
    font-family: 'interBold';
    font-weight: 700;
    font-size: 32px;
    color: #000000;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  h5 {
    margin-top: 5px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  label {
    display: inline-block;
    margin-bottom: 5px;
    font-family: 'interRegular';
    font-size: 16px !important;
    color: #000000 !important;
  }
  .uk-legend {
    margin-bottom: 0;
  }
  .uk-button[disabled] {
    background-color: #f4f4f4;
  }
</style>
